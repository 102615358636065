import FavoritesManager from '../../../../../global/js/classes/favorites-manager';
import ValueYourTradeWidget from '../../../../../global/js/misc/value-your-trade-widget/valueYourTradeWidget';

class PostsOverview3 {
    /**
     * Main container of the filter block
     * @type {HTMLElement}
     */
    container;

    /**
     * Main container of the Typesense search block
     * @type {HTMLElement}
     */
    typesenseContainer;

    /**
     * 'data-id' attribute value of the Typesense HTML root element
     * @type {string}
     */
    blockId = '';

    /**
     * HTML element of the loader item
     * @type {HTMLElement}
     */
    loader;

    /**
     * swm instance of the Typesense (instantsearch)
     * @type {object|null}
     */
    swtInstance = null;

    /**
     * Facets labels map
     * @type {{}}
     */
    facetsLabelsMap = {};

    /**
     * Promo cards
     * @type {{}}
     */
    promoCards = {};

    /**
     * Observers map
     * @type {{}}
     */
    observers = {};

    /**
     * Search page title HTML element
     * @type {HTMLElement | null}
     */
    titleElement = null;

    /**
     * Clear Refinements widget element
     * @type {HTMLElement | null}
     */
    clearRefinementsElement = null;

    /**
     * Input[type='search'] fields
     * @type {HTMLCollection | null}
     */
    searchFields = null;

    /**
     * Div class="js-saved-header"
     * @type {HTMLElement|null}
     */
    savedBlockHeader = null;

    /**
     * Div class="js-saved-items"
     * @type {HTMLElement|null}
     */
    savedBlockItems = null;

    /**
     * Div class="js-saved-button"
     * @type {HTMLElement|null}
     */
    savedBlockButton = null;

    /**
     * FavouritesManager Class Instance
     * @type {null|HTMLElement}
     */
    favouritesBlock = null;

    /**
     * Button for clearing the favourites list
     * @type {null|HTMLElement}
     */
    clearFavouritesButton = null;

    constructor(container) {
        this.container = container;
        this.typesenseContainer = this.container.querySelector(
            '.cmswt-InstantSearch',
        );
        this.blockId = this.typesenseContainer.dataset.id;
        this.swtInstance = window.swtInstance[this.blockId] ?? null;

        if (!this.swtInstance) {
            throw new Error('swt Instance is not defined');
        }

        this.loader = this.container.querySelector(
            '[data-role="typesense-loader"]',
        );
        this.titleElement = this.container.querySelector('[data-role="title"]');
        this.filterPanelToggle = this.container.querySelector(
            '.cmswt-FilterPanel-toggle',
        );
        this.filterPanelShow = this.container.querySelector(
            '.cmswt-FilterPanel-items',
        );
        this.clearRefinementsElement = this.container.querySelector(
            '[data-role="clear-refinements"]',
        );
        this.searchFields = this.container.querySelectorAll(
            'input[type="search"]',
        );

        this.savedBlockHeader =
            this.container.querySelector('.js-saved-header');
        this.savedBlockItems = this.container.querySelector('.js-saved-items');
        this.savedBlockButton =
            this.container.querySelector('.js-saved-button');
        this.favouritesBlock = this.container.querySelector('.js-saved-items');
        this.clearFavouritesButton = this.container.querySelector(
            `[data-role="clear-favourites"]`,
        );
        this.favouritesManager = new FavoritesManager();
        this.facetsLabelsMap = this.getFacetsLabelsMap();
        this.promoCards = this.getPromoCards();
        this.addListeners();
        this.initObservers();
        this.updateTitle();
        // Adding the 'Value Your Trade' widget to the overview page
        ValueYourTradeWidget.getInstance();
    }

    /**
     * Attaches event listeners
     */
    addListeners() {
        window.addEventListener('load', this.windowLoadHandler.bind(this));
        this.swtInstance.on(
            'render',
            this.instantsearchRenderHandler.bind(this),
        );
        window.addEventListener('click', this.windowClicksHandler.bind(this));
        this.filterPanelToggle.addEventListener(
            'click',
            this.filterPanelBgOverlay.bind(this),
        );
        if (this.savedBlockHeader) {
            this.savedBlockHeader.addEventListener(
                'click',
                this.toggleDisplayAndRotate.bind(this),
            );
        }
        if (this.clearFavouritesButton) {
            this.clearFavouritesButton.addEventListener(
                'click',
                this.clearFavouritesHandler.bind(this),
            );
        }
        window.addEventListener(
            'favoriteModified',
            this.favouritesModifiedHandler.bind(this),
        );
    }

    /**
     * Fires once window is ready
     */
    windowLoadHandler() {
        this.updateFavouritesBlock();
    }

    clearFavouritesHandler(event) {
        event.preventDefault();

        this.favouritesManager.clearFavourites();
    }

    favouritesModifiedHandler(event) {
        this.updateFavouritesBlock();
        this.updateCardsFavouriteButton();
    }

    /**
     * Update Favourites Listings Block
     */
    updateFavouritesBlock() {
        if (!this.favouritesBlock) {
            return;
        }
        const itemsBlock = this.favouritesBlock.querySelector(
            `[data-role="favourites-list"]`,
        );
        const buttonBlock = this.favouritesBlock.querySelector(
            `[data-role="clear-all-block"]`,
        );
        if (!itemsBlock || !buttonBlock) {
            return;
        }

        const favourites = this.favouritesManager.getFavourites();
        let innerHtml = '';
        if (Object.keys(favourites) < 1) {
            innerHtml =
                '<span class="block p-2">No saved vehicles found</span>';
            buttonBlock.classList.add('hidden');
        } else {
            innerHtml = '';
            buttonBlock.classList.remove('hidden');
        }
        itemsBlock.innerHTML = innerHtml;
        for (const postId in favourites) {
            const listingData = favourites[postId];
            try {
                const listingElement =
                    this.getFavouriteListingElement(listingData);
                if (listingElement) {
                    itemsBlock.appendChild(listingElement);
                }
            } catch (error) {
                console.log('Error:', error);
            }
        }
    }

    /**
     * Returns HTML markup for the listing element saved as Favourite
     *
     * @param listingData
     * @return {HTMLDivElement|null}
     */
    getFavouriteListingElement(listingData) {
        if (!listingData) {
            return null;
        }

        const listingElement = document.createElement('div');
        const baseClass = 'saved-listings';
        const elementInnerHtml = `
            <div class="${baseClass}__item-inner">
                <div class="${baseClass}__item-left">
                    <div class="${baseClass}__item-header">
                        <span class="${baseClass}__item-year">${
            listingData.year ?? ''
        }</span>
                        <span class="${baseClass}__item-title">${
            listingData.marketingTitle ?? ''
        }</span>
                    </div>
                    <div class="${baseClass}__item-body">
                        <span class="${baseClass}__item-details">${
            listingData.stock ?? ''
        } — ${listingData.price ?? ''}</span>
                    </div>
                </div>
                <div class="${baseClass}__item-right">
                    <button class="${baseClass}__item-remove" data-role="remove-from-favourites-btn">
                        ✕
                    </button>
                </div>
            </div>
        `;

        listingElement.classList.add(`${baseClass}__item`);
        listingElement.setAttribute('data-listing-id', listingData.postId);
        listingElement.innerHTML = elementInnerHtml;
        return listingElement;
    }

    /**
     * Initializing needed observers
     */
    initObservers() {
        // List of observers needed to init
        const observerTargets = {
            // Current Refinements Widget
            currentRefinements: {
                selector: '[data-role="typesense-current-refinements-widget"]',
                callback: this.currentRefinementsObserverCallback.bind(this),
            },
        };

        // Options for the observer (which mutations to observe) - the same for each observer
        const observerConfig = {
            attributes: true,
            childList: true,
            subtree: true,
        };

        // Initializing all the observers
        for (const observerName in observerTargets) {
            // Function which will run as an observer callback
            const callback = observerTargets[observerName].callback;
            // Selector of mutation target
            const targetNode = this.container.querySelector(
                observerTargets[observerName].selector,
            );
            if (!targetNode) {
                continue;
            }

            // New MutationObserver instance
            const observer = new MutationObserver(callback);

            // Create an observer instance linked to the callback function
            this.observers[observerName] = observer;

            // Adding method which allows us to disconnect current mutation observer
            this.observers[observerName].remove = observer.disconnect;
            // Adding method which allows us to connect current mutation observer
            this.observers[observerName].add = () => {
                observer.observe(targetNode, observerConfig);
            };
            // Enabling observer by default as its initial state
            this.observers[observerName].add();
        }
    }

    /**
     * Function which fires when a new mutation record occurs on the currentRefinement widget container
     * @param mutationList
     * @param observer
     */
    currentRefinementsObserverCallback(mutationList, observer) {
        for (const mutation of mutationList) {
            if (mutation.type !== 'childList') {
                continue;
            }
            let items = [];
            if (
                mutation.target.matches('.cmswt-CurrentRefinements') &&
                mutation.addedNodes.length > 0
            ) {
                items = [
                    ...mutation.target.querySelectorAll(
                        'li.ais-CurrentRefinements-item',
                    ),
                ];
            } else if (
                mutation.target.matches('ul.ais-CurrentRefinements-list') &&
                mutation.addedNodes.length > 0
            ) {
                items = [...mutation.addedNodes];
            } else if (
                mutation.target.matches('li.ais-CurrentRefinements-item')
            ) {
                items = [mutation.target];
            } else if (
                mutation.target.matches('.ais-CurrentRefinements-label')
            ) {
                items = [
                    mutation.target.closest('li.ais-CurrentRefinements-item'),
                ];
            } else {
                continue;
            }
            if (items.length > 0) {
                // In order to remove infinite looping we have to disconnect observer in prior performing custom actions
                this.observers.currentRefinements.remove();
                this.updateCurrentRefinementWidgetLabel(items);
                // And then connect observers back
                this.observers.currentRefinements.add();
            }
        }
    }

    /**
     * Modifies label text for the passed refinements
     * @param {array} items
     */
    updateCurrentRefinementWidgetLabel(items = []) {
        [...items].forEach((item) => {
            const labelElement = item.querySelector(
                '.ais-CurrentRefinements-label',
            );
            if (!labelElement) {
                return;
            }
            let label = labelElement.innerText.toLowerCase().replace(':', '');
            if (!label) {
                return;
            }
            for (const facetRawName in this.facetsLabelsMap) {
                if (label.includes(facetRawName.toLowerCase())) {
                    labelElement.innerHTML = this.facetsLabelsMap[facetRawName];
                    return;
                }
            }
        });
    }

    /**
     * Fires on 'render' event trigger by Typesense
     */
    instantsearchRenderHandler() {
        if (this.swtInstance.status === 'idle') {
            this.updateListingsCards();
            this.updateCardsFavouriteButton();
            this.hideLoader();
        } else {
            this.handleModelsFacet(
                this.swtInstance.helper.getRefinements('maker'),
                this.swtInstance.helper.getRefinements('model'),
            );
            this.handleBodyTypeFacet(
                this.swtInstance.helper.getRefinements('bodyTypeGeneral'),
                this.swtInstance.helper.getRefinements('bodyTypeSpecific'),
            );
            this.updateTitle();
            this.showLoader();
        }
        this.updateClearRefinementsWidget();
    }

    /**
     * Updates the search page title depending on the state facet value
     */
    updateTitle() {
        if (!this.titleElement) {
            return;
        }

        const stateValues = this.swtInstance.helper.getRefinements('state');
        let title = 'All Inventory';
        if (Array.isArray(stateValues) && stateValues.length > 0) {
            const formattedValues = stateValues.map((item) => {
                return item.value.toLowerCase();
            });
            if (
                formattedValues.includes('new') &&
                formattedValues.includes('pre-owned') &&
                formattedValues.includes('certified pre-owned')
            ) {
                title = 'All Inventory';
            } else if (formattedValues.includes('new')) {
                if (formattedValues.includes('pre-owned')) {
                    title = 'New and Pre-Owned Vehicles';
                } else if (formattedValues.includes('certified pre-owned')) {
                    title = 'New and Certified Pre-Owned Vehicles';
                } else {
                    title = 'New Vehicles';
                }
            } else if (formattedValues.includes('pre-owned')) {
                if (formattedValues.includes('certified pre-owned')) {
                    title = 'Certified and Non-Certified Pre-Owned Vehicles';
                } else {
                    title = 'Pre-Owned Vehicles';
                }
            } else if (formattedValues.includes('certified pre-owned')) {
                title = 'Certified Pre-Owned Vehicles';
            }
        }

        this.titleElement.innerHTML = title;
    }

    /**
     * Hides Or Shown the Model facet depending on the Maker facet value
     * @param chosenMakers
     * @param chosenModels
     */
    handleModelsFacet(chosenMakers = [], chosenModels = []) {
        const modelFacet = this.typesenseContainer.querySelector(
            '.cmswt-Filter-model',
        );
        if (modelFacet) {
            if (chosenMakers.length > 0 || chosenModels.length > 0) {
                modelFacet.classList.remove('!hidden');
            } else {
                modelFacet.classList.add('!hidden');
            }
        }
    }

    /**
     * Hides Or Shows the Body Type facet depending on the Type of Vehicle facet value
     * @param chosenBodyTypeGeneral
     * @param chosenBodyTypeSpecific
     */
    handleBodyTypeFacet(
        chosenBodyTypeGeneral = [],
        chosenBodyTypeSpecific = [],
    ) {
        const bodyTypeSpecificFacet = this.typesenseContainer.querySelector(
            '.cmswt-Filter-bodyTypeSpecific',
        );
        if (bodyTypeSpecificFacet) {
            if (
                chosenBodyTypeGeneral.length > 0 ||
                chosenBodyTypeSpecific.length > 0
            ) {
                bodyTypeSpecificFacet.classList.remove('!hidden');
            } else {
                bodyTypeSpecificFacet.classList.add('!hidden');
            }
        }
    }

    /**
     * Shows block's loader
     */
    showLoader() {
        if (this.loader) {
            this.loader.dataset.visible = 'true';
        }
    }

    /**
     * Hides block's loader
     */
    hideLoader() {
        if (this.loader) {
            this.loader.dataset.visible = 'false';
        }
    }

    /**
     * Fires on any clicks on the window object
     * @param {Event} event
     */
    windowClicksHandler(event) {
        const target = event.target;
        if (
            target.closest('.ais-Panel-header') &&
            !target.closest('.ais-Panel-collapseButton')
        ) {
            event.preventDefault();
            this.panelHeaderClicksHandler(target.closest('.ais-Panel-header'));
        } else if (target.closest('button.ais-ClearRefinements-button')) {
            this.clearRefinementsClickHandler(event);
        } else if (target.closest(`[data-role='remove-from-favourites-btn']`)) {
            this.removeFromFavouritesHandler(event);
        } else if (
            target.closest(`[data-role='listing-remove-from-favourites']`)
        ) {
            this.toggleFromFavouritesHandler(event);
        }
    }

    toggleFromFavouritesHandler(event) {
        event.preventDefault();

        const listingElement = event.target.closest(`[data-listing-id]`);
        if (!listingElement) {
            return;
        }
        const listingData = this.getListingCardJsData(listingElement);
        this.favouritesManager.toggleFavorite(listingData);
    }

    removeFromFavouritesHandler(event) {
        event.preventDefault();

        const listingElement = event.target.closest(`[data-listing-id]`);
        if (!listingElement) {
            return;
        }
        const postId = parseInt(listingElement.dataset?.listingId ?? 0);
        this.favouritesManager.removeListingFromFavourites(postId);
    }

    getListingCardJsData(cardElement) {
        let defaults = {
            marketingTitle: '',
            newOrPreOwned: '',
            postId: 0,
            postTitle: '',
            price: '',
            stock: '',
            year: '',
        };
        // Get JS data from the container element
        let elementJsData = cardElement.dataset?.js ?? '';
        try {
            elementJsData = elementJsData ? JSON.parse(elementJsData) : {};
            elementJsData = elementJsData
                ? {
                      ...elementJsData,
                      postId: parseInt(elementJsData.postId),
                  }
                : {};
        } catch (error) {
            console.warn('Could not obtain valid data for the current listing');
        }

        return {
            ...defaults,
            ...elementJsData,
        };
    }

    /**
     * Creates div which serves as bg overlay behind the mobile version of filter panel and toggles it depending on if mobile filter panel is open or closed
     */
    filterPanelBgOverlay() {
        // Create the overlay div
        const overlayDiv = document.createElement('div');
        overlayDiv.classList.add('filter-panel-bg-overlay');

        // Insert the overlay div right after the filterPanelToggle element
        this.filterPanelToggle.insertAdjacentElement('afterend', overlayDiv);

        // Add any other logic you need for the overlay

        // Listen for changes in the class of this.filterPanelShow
        const observer = new MutationObserver(() => {
            if (
                !this.filterPanelShow.classList.contains(
                    'cmswt-FilterPanel-items--show',
                )
            ) {
                // Remove the overlay div if the class is no longer present
                overlayDiv.remove();
                observer.disconnect(); // Stop observing once the class is removed
            }
        });

        // Start observing changes in the class of this.filterPanelShow
        observer.observe(this.filterPanelShow, {
            attributes: true,
            attributeFilter: ['class'],
        });
    }

    /**
     * Fires when user clicks facet panel header
     * @param {HTMLElement} header
     */
    panelHeaderClicksHandler(header) {
        const button = header.querySelector('.ais-Panel-collapseButton');
        if (button) {
            button.click();
        }
    }

    /**
     * Returns facets labels map, collecting from the current refinement widget data attribute
     * @returns {{}|any|{}}
     */
    getFacetsLabelsMap() {
        const currentRefinementsWidgetContainer = this.container.querySelector(
            `[data-role='typesense-current-refinements-widget']`,
        );
        if (!currentRefinementsWidgetContainer) {
            return {};
        }

        return currentRefinementsWidgetContainer.dataset.labels
            ? JSON.parse(currentRefinementsWidgetContainer.dataset.labels)
            : {};
    }

    /**
     * Returns a list of promo cards
     * @returns {{}}
     */
    getPromoCards() {
        const templatesContainer = this.container.querySelector(
            `[data-role='templates-container']`,
        );
        if (!templatesContainer) {
            return {};
        }

        const promoCards = templatesContainer.querySelectorAll(
            `template[data-role='promo-card']`,
        );
        if (promoCards.length < 1) {
            return {};
        }
        const cards = {};
        promoCards.forEach((template) => {
            const promoId = parseInt(template.dataset.promoId) ?? null;
            if (!promoId) {
                return;
            }
            cards[promoId] = template.innerHTML;
        });

        return cards;
    }

    /**
     * Updates listings cards, by placing promo cards at certain positions
     */
    updateListingsCards() {
        const cardsContainer = this.container.querySelector('.ais-Hits-list');
        if (!cardsContainer) {
            return;
        }

        // We have to remove promo cards from the previous query manually, otherwise they will be shown with the new ones :/
        const previousPromoCards = cardsContainer.querySelectorAll(
            '.ais-Hits-item .m-card-promo',
        );
        previousPromoCards.forEach((promo) => {
            promo.parentNode.remove();
        });

        // Listings cards for the current query
        const cards = cardsContainer.querySelectorAll('.ais-Hits-item');
        // Current Promo Cards
        const promoCards = this.promoCards;
        if (Object.keys(promoCards).length < 1) {
            return;
        }
        // Calculate how many promo cards should be placed among listings cards
        const promoCardsNeeded = Math.floor(cards.length / 5);
        if (promoCardsNeeded < 1) {
            return;
        }
        const promoCardsIndexes = [2, 9, 14];

        // Insert promo cards within the listings cards container at particular places
        let promoCardsAdded = 0;
        cards.forEach((listingCard, index) => {
            if (
                promoCardsIndexes.includes(index) &&
                promoCardsAdded < promoCardsNeeded
            ) {
                const clone = listingCard.cloneNode(false);
                const promoCard =
                    this.promoCards[
                        Object.keys(this.promoCards)[promoCardsAdded]
                    ];
                if (!promoCard) {
                    return;
                }
                clone.innerHTML = promoCard;
                listingCard.insertAdjacentElement('afterend', clone);
                promoCardsAdded++;
            }
        });
    }

    updateCardsFavouriteButton() {
        // Listings cards for the current query
        const cards = this.container.querySelectorAll('.ais-Hits-item');
        cards.forEach((card) => {
            const listingElement = card.querySelector(`[data-listing-id]`);
            if (!listingElement) {
                return;
            }
            const button = listingElement.querySelector(
                `[data-role='listing-remove-from-favourites']`,
            );
            const listingId = parseInt(listingElement.dataset?.listingId ?? 0);
            if (button) {
                if (this.favouritesManager.isInFavourites(listingId)) {
                    button.classList.add('clicked');
                } else {
                    button.classList.remove('clicked');
                }
            }
        });
    }

    /**
     * Updating the 'Clear Refinements' disabled attribute value depending on the current query
     */
    updateClearRefinementsWidget() {
        if (!this.clearRefinementsElement) {
            return;
        }
        const button = this.clearRefinementsElement.querySelector(
            'button.ais-ClearRefinements-button',
        );
        if (!button) {
            return;
        }
        const query = this.swtInstance.helper?.state?.query ?? '';
        if (query) {
            button.disabled = false;
            button.classList.remove('ais-ClearRefinements-button--disabled');
        }
    }

    /**
     * Fires when the 'Clear Refinements' button is clicked.
     * @param {Event} event
     */
    clearRefinementsClickHandler(event) {
        [...this.searchFields].map((field) => {
            const container = field.closest('.ais-SearchBox');
            if (container) {
                const resetButton = container.querySelector(
                    'button.ais-SearchBox-reset',
                );
                if (resetButton) {
                    resetButton.click();
                }
            }
        });
    }

    toggleDisplayAndRotate() {
        if (!this.savedBlockItems) {
            return;
        }
        if (this.savedBlockItems.classList.contains('hidden')) {
            this.savedBlockItems.classList.remove('hidden');
            this.savedBlockButton.classList.remove('rotate-180');
        } else {
            this.savedBlockItems.classList.add('hidden');
            this.savedBlockButton.classList.add('rotate-180');
        }
    }
}

function postsOverview3() {
    const container = document.querySelector(`[data-module='postsOverview3']`);
    if (container) {
        new PostsOverview3(container);
    }
}

export default postsOverview3;
