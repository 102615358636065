import 'tippy.js/dist/tippy.css';

import tippy from 'tippy.js';

import FavoritesManager from '../../../../global/js/classes/favorites-manager';
import ValueYourTradeWidget from '../../../../global/js/misc/value-your-trade-widget/valueYourTradeWidget';

class ListingPageSidebar {
    /**
     *
     * @type {HTMLElement}
     */
    sidebarElement;

    /**
     * @type {HTMLCollection}
     */
    saveButtons;

    /**
     * @type {HTMLCollection}
     */
    shareButtons;

    /**
     * @type {FavoritesManager}
     */
    favouritesManager;

    /**
     * @type {object}
     */
    listingData;

    constructor(container) {
        if (!container) {
            return;
        }
        this.sidebarElement = container;
        this.saveButtons = document.querySelectorAll('.save-btn');
        this.shareButtons = document.querySelectorAll('.share-btn');
        this.favouritesManager = new FavoritesManager();
        this.listingData = this.getListingJsData();

        this.initListeners();
    }

    getListingJsData() {
        let defaults = {
            marketingTitle: '',
            newOrPreOwned: '',
            postId: 0,
            postTitle: '',
        };
        // Get JS data from the container element
        let elementJsData = this.sidebarElement.dataset?.js ?? '';
        try {
            elementJsData = elementJsData ? JSON.parse(elementJsData) : {};
            elementJsData = elementJsData
                ? {
                      ...elementJsData,
                      postId: parseInt(elementJsData.postId),
                  }
                : {};
        } catch (error) {
            console.warn('Could not obtain valid data for the current listing');
        }

        return {
            ...defaults,
            ...elementJsData,
        };
    }

    initListeners() {
        window.addEventListener('load', this.windowLoadHandler.bind(this));
        [...this.saveButtons].forEach((saveBtn) => {
            saveBtn.addEventListener(
                'click',
                this.saveButtonClickHandler.bind(this),
            );
        });
        [...this.shareButtons].forEach((shareBtn) => {
            shareBtn.addEventListener(
                'click',
                this.shareButtonClickHandler.bind(this),
            );
        });
        window.addEventListener(
            'favoriteRemoved',
            this.removedFromFavouritesHandler.bind(this),
        );
        window.addEventListener(
            'favoriteAdded',
            this.addedToFavouritesHandler.bind(this),
        );
    }

    windowLoadHandler() {
        // Save to favourites buttons
        const listingId = this.listingData.postId;
        if (listingId && this.favouritesManager.isInFavourites(listingId)) {
            this.updateSaveButtons();
        } else {
            this.updateSaveButtons(false);
        }
        // Value Your Trade modal window
        ValueYourTradeWidget.getInstance();
        // Tooltip
        this.initTooltips();
    }

    initTooltips() {
        const windowWidth = window.innerWidth;
        const isSmallRes = windowWidth < 768;
        // Rebates Tooltip
        const rebatesTooltipContent = this.getRebatesTooltipContent();
        if (rebatesTooltipContent) {
            tippy(`[data-role='rebates-tooltip-btn']`, {
                content: rebatesTooltipContent,
                placement: isSmallRes ? 'top-end' : 'left',
                arrow: true,
                theme: 'translucent',
                interactive: true,
                allowHTML: true,
                maxWidth: isSmallRes ? 0.8 * windowWidth : 'none',
            });
        }
        // Claycooley.com Price tooltip
        const CCComPriceTooltipContent = this.getCCComPriceTooltipContent();
        if (CCComPriceTooltipContent) {
            tippy(`[data-role='claycooley-price-tooltip-btn']`, {
                content: CCComPriceTooltipContent,
                placement: isSmallRes ? 'top-end' : 'left',
                arrow: true,
                theme: 'translucent',
                interactive: true,
                allowHTML: true,
                maxWidth: isSmallRes ? 0.8 * windowWidth : 'none',
            });
        }
    }

    /**
     *
     * @return {string}
     */
    getRebatesTooltipContent() {
        const contentElement = this.sidebarElement.querySelector(
            `[data-role='rebates-tooltip-content']`,
        );
        if (!contentElement) {
            return '';
        }

        return contentElement.innerHTML;
    }

    getCCComPriceTooltipContent() {
        const contentElement = this.sidebarElement.querySelector(
            `[data-role='claycooley-price-tooltip-content']`,
        );
        if (!contentElement) {
            return '';
        }

        return contentElement.innerHTML;
    }

    saveButtonClickHandler(event) {
        event.preventDefault();

        this.favouritesManager.toggleFavorite(this.listingData);
    }

    shareButtonClickHandler(event) {
        event.preventDefault();

        const newOrPreOwned = this.listingData.newOrPreOwned ?? '';
        const marketingTitle = this.listingData.marketingTitle ?? '';
        const postTitle = this.listingData.postTitle ?? '';
        const emailSubject = encodeURIComponent(
            `${marketingTitle} - ${postTitle} (${newOrPreOwned})`,
        );
        window.location.href = `mailto:?subject=${emailSubject}&body=${encodeURIComponent(
            window.location.href,
        )}`;
    }

    removedFromFavouritesHandler() {
        const removedProduct = event.detail?.product ?? {};
        if (removedProduct.postId === this.listingData.postId) {
            this.updateSaveButtons(false);
        }
    }

    addedToFavouritesHandler() {
        const addedProduct = event.detail?.product ?? {};
        if (addedProduct.postId === this.listingData.postId) {
            this.updateSaveButtons();
        }
    }

    updateSaveButtons(highlight = true) {
        [...this.saveButtons].forEach((saveBtn) => {
            if (highlight) {
                saveBtn.classList.add('clicked');
            } else {
                saveBtn.classList.remove('clicked');
            }
        });
    }
}

function sidebarInfo() {
    const sidebar = document.querySelector('[data-module="sidebarInfo"]');
    if (sidebar) {
        new ListingPageSidebar(sidebar);
    }
}

export default sidebarInfo;
